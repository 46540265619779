// import React from "react";

// import Project from "../components/project/Project";
// import dataProject from "../assets/fake-data/dataProject";
// import Roadmap2 from "../components/roadmap/Roadmap2";
// import Portfolio from "../components/portfolio/Portfolio";
// import dataPortfolio from "../assets/fake-data/data-portfolio";
// import Roadmap from "../components/roadmap/Roadmap";
// import dataTeam from "../assets/fake-data/dataTeam";
// import Testimonials from "../components/testimonials/Testimonials";
// import Faq3 from "../components/faqs/Faq3";
// import Faqs from "../components/faqs/Faqs";
// import dataFaqs from "../assets/fake-data/data-faq";
// import Banner3 from "../components/banner/Banner344";
// import About3 from "../components/about/About3";
// import Token from "../components/token/Token";
// import dataRoadMap from "../assets/fake-data/data-roadmap";
// import Team from "../components/team/Team";
// import Speciality from "../components/speciality/Speciality";
// import dataBox from "../assets/fake-data/data-box";
// import dataTestimonials from "../assets/fake-data/data-testimonials";
// import Counter from "../components/counter/Counter";
// import Blog from "../components/blog/Blog";
// import dataBlog from "../assets/fake-data/data-blog";
// import Header from "../components/header/Header"
// import Footer from "../components/footer/Footer";
// import Partner from "../components/partner/Partner";
// // import {headers} from "next/headers"

// function Home03(props) {
//   // const headerslist = headers();
//   // const countryCode = headerslist.get("cf-ipcountry") || "Unknown";
//   return (
//     <div className="home-3 wrapper">
//       <Header/>
//       <Banner3 />

//       <About3 />

//       {/* <Token /> */}

//       {/* <Project data={dataProject} /> */}

//       <Roadmap data={dataRoadMap} />

//       {/* <Team data={dataTeam} /> */}

//       {/* <Speciality data={dataBox} /> */}
//       <Partner/>
//       <Testimonials data={dataTestimonials} />

//       {/* <Counter /> */}

//       {/* <Blog data={dataBlog} /> */}

//       <Faqs data={dataFaqs} />

//       <Footer />
//     </div>
//   );
// }

// export default Home03;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Banner3 from "../components/banner/Banner3";
import About3 from "../components/about/About3";
import Roadmap from "../components/roadmap/Roadmap";
import Partner from "../components/partner/Partner";
import Testimonials from "../components/testimonials/Testimonials";
import Faqs from "../components/faqs/Faqs";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import dataRoadMap from "../assets/fake-data/data-roadmap";
import dataTestimonials from "../assets/fake-data/data-testimonials";
import dataFaqs from "../assets/fake-data/data-faq";

const blockedCountries = [
  "US", "PR", "VI", "AS", "MP"
];

function Home03() {
  const [isBlocked, setIsBlocked] = useState(false);

  useEffect(() => {
    // Fetch user's country code using ipapi or another geolocation APIiiiiii
    axios.get('https://ipapi.co/json/')
      .then(response => {
        const countryCode = response.data.country;
        if (blockedCountries.includes(countryCode) && countryCode !== "DO") {
          setIsBlocked(true);
        }
      })
      .catch(error => {
        console.error('Error fetching geolocation:', error);
      });
  }, []);

  if (isBlocked) {
    return (
      <div style={{ width: '80%', margin: 'auto'}} className="">
    <br/>
    <br/>
    <br/>
    <h1 className='h12' style={{textAlign:"center",fontSize:'30px'}}>Important Notice for United States Visitors</h1>
    <br/>
    <br/>
    <br/>
    <p style={{fontSize:'20px'}}>
       <span style={{fontSize:'30px'}}>Dear Visitor,</span> <br/><br/>
        Due to regulatory compliance and restrictions under United States securities laws, the RESPECT Token is 
        not available for sale to individuals or entities located in the United States or those defined as U.S. 
        persons under applicable regulations.<br/><br/>
        If you are accessing this site from the United States, we kindly ask that you refrain from participating in 
        any token sales or related activities.<br/><br/>
        We appreciate your understanding and support as we navigate global regulatory requirements.<br/><br/>
        Sincerely,<br/>
        The RESPECT Token Team<br/><br/>
        <strong>Disclaimer:</strong> This website is not intended for use in jurisdictions where its content or the RESPECT Token is 
        prohibited by law.
    </p>
    <br/>
    <br/>
   
</div>

    );
  }

  return (
    <div className="home-3 wrapper">
      <Header />
      <Banner3 />
      <About3 />
      <Roadmap data={dataRoadMap} />
      <Partner />
      <Testimonials data={dataTestimonials} />
      <Faqs data={dataFaqs} />
      <Footer />
    </div>
  );
}

export default Home03;
