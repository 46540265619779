
import img1 from '../images/layouts/new1.png.jpg'
import img2 from '../images/layouts/new2.jpg'
import img3 from '../images/layouts/news3.jpg'
import img4 from '../../assets/videos/sample-video-1.mp4'
import img5 from '../../assets/videos/sample-video-2.mp4'

const dataProject = [

    {
        id: 1,
        img: img1,
        title: '3D Digital Artwork'
    },
    {
        id: 2,
        img: img2,
        title: 'King Of Pirates'
    },
    {
        id: 3,
        img: img3,
        title: 'Pomeranian Doge'
    },
  


    

]

export default dataProject;