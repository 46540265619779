import React, { useState } from "react";

// import PageTitle from "../components/pagetitle/PageTitle";
import Header from "../components/header/Header"
import Counter from "../components/counter/Counter";
import Footer from "../components/footer/Footer";
import Faqs from "../components/faqs/Faqs";
import dataFaqs from "../assets/fake-data/data-faq";

import img1 from "../assets/images/layouts/about-01.png";
import img2 from "../assets/images/layouts/about-02.png";
import img3 from "../assets/images/layouts/about-03.png";
import img4 from "../assets/images/layouts/about-04.png";
import img5 from "../assets/images/layouts/about-05.png";

function VisionsMission(props) {
  const [dataImg] = useState([
    {
      id: 1,
      img: img1,
      class: "img1",
    },
    {
      id: 2,
      img: img4,
      class: "img2",
    },
    {
      id: 3,
      img: img3,
      class: "img3",
    },
    {
      id: 4,
      img: img2,
      class: "img4",
    },
    {
      id: 5,
      img: img5,
      class: "img5",
    },
  ]);
  return (
    <div className="page-visions wrapper">
      {/* <PageTitle title="Visions & Mission" /> */}
      <Header/>
      <section className="about">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="block-text">
                <h6 className="sub-heading">
                  <span>Visions</span>
                </h6>
                <h3 className="heading wow" data-splitting>
                  Our Vision
                </h3>
                <p className="mb-17">
                The RESPECT token envisions a future where the digital and physical realms of Taekwondo and sports are seamlessly integrated. As the cornerstone of the Taekwondo and sports ecosystem, RESPECT aims to empower all participants, including sports athletes, referees, coaches, officials, club owners, and martial artist with a token-based rewards system that fosters trust, collaboration, and recognition. This initiative will build a global ecosystem that leverages blockchain technology to promote transparency and fairness, ultimately leading to a more engaged and motivated community.

                </p>
              </div>
              <div className="block-text">
                <h6 className="sub-heading">
                  <span>Mission</span>
                </h6>
                <h3 className="heading wow" data-splitting>
                  Our Mission
                </h3>
                <p>
                The mission of the RESPECT token is to unite the digital and physical worlds of Taekwondo and sports, providing a cohesive platform for professional and personal growth. This token will incentivize key figures in the community—athletes, coaches, referees, officials and clubs—by offering development pathways and rewards. The RESPECT token also strives to uphold Taekwondo and sports traditions while integrating innovative blockchain technology to elevate Taekwondo and sports global impact. It will serve as a unifying force across different Taekwondo and sports, creating unmatched opportunities for future generations and setting new standards for excellence.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="about__right">
                <div className="images">
                  {dataImg.map((idx) => (
                    <img
                      key={idx.id}
                      className={idx.class}
                      src={idx.img}
                      alt="cyfonii"
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container" style={{ marginTop: 100 }}>
          <div className="row">
            <p>
              The mission of the "RESPECT" token is to seamlessly unite the
              digital and physical worlds of Taekwondo and Martial Arts, forming
              a cohesive ecosystem within Kombat Taekwondo. This token will act
              as a powerful incentive for fighters, referees, school owners, and
              the entire Taekwondo Martial Arts communities, paving the way for
              professional growth and personal enrichment.
            </p>
          </div>
        </div> */}
      </section>

      {/* <Counter />

      <Faqs data={dataFaqs} /> */}

      <Footer />
    </div>
  );
}

export default VisionsMission;
