import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import './App.scss'
import ScrollToTop from "./ScrollToTop";

import { WagmiConfig } from "wagmi";
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi/react";

import { baseMainnet } from "./wagmiChainId";
const projectId = "cba73ada547c01c1a64d7725fb732495";
const chains = [baseMainnet];
export const wagmiConfig = defaultWagmiConfig({ chains, projectId });

createWeb3Modal({
    wagmiConfig,
    projectId,
    chains,
    themeMode: "dark",
  });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    
        <WagmiConfig config={wagmiConfig}>
        <BrowserRouter>
            <ScrollToTop />
            <App />
        </BrowserRouter>
        </WagmiConfig>
   
);

