import React from "react";
import UserTable from "./UserTable";

const UserDashboard = () => {
  return (
    <>
      <style>
        {`
          /* General Styles */
          .dashboard-container {
            max-width: 100%;
            background-color: #1F223E;
            min-height: 100vh;
            width: 100%;
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }
          .dashboard-header {
            background-color: #1F223E;
            border: 1px solid #CBCBD0;
            padding: 16px;
            border-radius: 10px 10px 0 0;
          }
          .header-text {
            color: white;
            font-size: 18px;
            font-weight: 600;
            margin: 0;
          }
          .dashboard-table {
            background-color: #FFFFFF;
            border: 1px solid #CBCBD0;
            padding: 16px;
            border-radius: 0 0 10px 10px;
            overflow-x: auto;
            margin-top: 16px;
            flex: 1;
          }

          /* Mobile Styles */
          @media (max-width: 768px) {
            .header-text {
              font-size: 11px; /* Reduce font size on mobile */
            }
            .dashboard-container {
              padding: 10px; /* Reduced padding on mobile */
            }
            .dashboard-header {
              padding: 12px; /* Reduced padding on mobile */
            }
            .dashboard-table {
              padding: 12px;
              width:400px; /* Reduced padding on mobile */
            }
          }

          /* Tablet and larger screen sizes */
          @media (max-width: 1024px) {
            .dashboard-container {
              padding: 15px;
            }
            .dashboard-header {
              padding: 14px;
            }
              .header-text{
              }
            .dashboard-table {
              padding: 14px;
            }
          }
        `}
      </style>

      <div className="dashboard-container">
        <div className="dashboard-header">
          <p className="header-text">
            Respect Token Purchase Details (User Dashboard)
          </p>
        </div>
        <div className="dashboard-table">
          <UserTable />
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
