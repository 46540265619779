import React from "react";
import AdminTable from "./AdminTable";

const AdminDashboard = () => {
  return (
    <div
      style={{
        maxWidth: "100%",
        background: "#1F223E",
        minHeight: "100vh",
        width: "100%",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          background: "#1F223E",
          border: "1px solid #CBCBD0",
          padding: "16px",
          borderRadius: "10px 10px 0 0",
        }}
      >
        <p
          style={{
            color: "white",
            fontSize: "18px",
            fontWeight: "600",
            margin: 0,
          }}
        >
          Respect Token Purchase Details (Admin Dashboard)
        </p>
      </div>
      <div
        style={{
          background: "#FFFFFF",
          border: "1px solid #CBCBD0",
          padding: "16px",
          borderRadius: "0 0 10px 10px",
        }}
      >
        <AdminTable />
      </div>
    </div>
  );
};

export default AdminDashboard;
