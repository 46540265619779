import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

import img from "../../assets/images/layouts/deco.png";
import imgFighter from "../../assets/images/layouts/coming-soon.png";

const initialTimer = 1296000; // 1 hour for example, adjust as needed

const boxStyle = {
  background:
    "linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%)",
  backdropFilter: "blur(4px)",
  padding: "12px",
  position: "relative",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  width: "177px",
  height: "102px",
  borderRadius: "20px",
  overflow: "hidden",
  display: "flex", // Added for vertical centering
  flexDirection: "column", // Added for vertical centering
  alignItems: "center", // Added for horizontal centering
  justifyContent: "center", // Added for vertical centering
  margin: "0 5px", // Added for 10px gap between boxes (5px on each side)
};

const numberStyle = {
  color: "#FFF",
  fontSize: "32px",
  fontWeight: 700,
};

const labelStyle = {
  marginTop: "12px",
  //color: "#697783",
  color: "#CED1D3",
  fontSize: "16px",
  fontWeight: 400,
  textTransform: "uppercase", // Added to capitalize the labels
};

// Example countdown values
const countdownValues = {
  days: 10,
  hours: 5,
  minutes: 30,
  seconds: 15,
};

const headingBoxStyle = {
  background:
    "linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%)",
  backdropFilter: "blur(2px)",
  borderRadius: "20px",
  display: "flex", // For horizontal alignment
  justifyContent: "center", // For horizontal alignment
  alignItems: "center", // For vertical alignment
  padding: "12px", // Optional, adjust to your liking
  marginBottom: "20px", // Optional, for spacing between heading and boxes
  width: "100%",
};

const headingTextStyle = {
  // Since you're using <h4>, these styles are optional. Adjust as needed.
  color: "#FFF", // Assuming white, adjust as per your theme
  fontSize: "18px", // Adjust as per your preference
  fontWeight: 600, // Adjust as per your preference
  textTransform: "uppercase", // If you want the title in uppercase
};

const containerStyle = {
  display: "flex",
  flexDirection: "column", // Stack elements vertically
  alignItems: "center", // Center horizontally
  width: "100%",
};

const countdownStyle = {
  // Add your countdown styles here, or keep using className="coundown"
  marginBottom: "10px", // Space between countdown and inputs
  marginTop: "10px",
};

const formContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

const inputBoxStyle = {
  // Your existing inputBoxStyle
  background: "rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(2px)",
  borderRadius: "10px",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  font: "300 14px/1.5 sans-serif",
  color: "#fff",
  padding: "15px",
  boxShadow: "none",
  flex: 1,
  height: "54px",
  marginRight: "5px", // Gap between inputs
  placeholder: { color: "#fff" }, // Set placeholder color to white
};

const inputBoxStyleLast = {
  ...inputBoxStyle, // Inherit all styles from inputBoxStyle
  marginRight: "0px", // Reset margin for the last input
};

function Banner4(props) {
  const [countdownValues, setCountdownValues] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [timer, setTimer] = useState(initialTimer);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
        updateCountdownValues(timer - 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1000); // Update every 1000 milliseconds (1 second)

    return () => clearInterval(intervalId);
  }, [timer]);

  const updateCountdownValues = (timeLeft) => {
    const days = Math.floor(timeLeft / (60 * 60 * 24));
    const hours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((timeLeft % (60 * 60)) / 60);
    const seconds = timeLeft % 60;

    setCountdownValues({ days, hours, minutes, seconds });
  };

  useEffect(() => {
    updateCountdownValues(timer);
  }, []); // Initialize countdown values on mount

  return (
    <section className="banner s3">
      <div className="shape"></div>
      <div className="shape right"></div>
      <div className="shape s3 right"></div>

      <div className="container big">
        <div className="row">
          <div className="col-xl-5 center col-md-12">
            <div className="banner__left">
              <div className="block-text">
                <h4 className="heading head">
                  Comming Soon{" "}
                  <span className="arlo_tm_animation_text_word"></span>
                </h4>
               
                <h5 className="heading head">
                  {" "}
                  <span className="arlo_tm_animation_text_word">
                  In 2025{" "}
                  </span>
                </h5>
                <Link to="/contact" className="action-btn">
                  <span>Buy RESPECT</span>
                </Link>
                
              </div>

              {/* <div className="couter-list">
                <div className="couter-box">
                  <h4 className="numb">
                    11.6Bil<span>+</span>
                  </h4>
                  <p>Total Staked</p>
                </div>
                <div className="couter-box">
                  <h4 className="numb">
                    88%<span>+</span>
                  </h4>
                  <p>APY</p>
                </div>
                <div className="couter-box">
                  <h4 className="numb">
                    40%<span>+</span>
                  </h4>
                  <p>Presale </p>
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-xl-7 col-md-12">
            <div className="banner__right" style={{ padding: "0" }}>
              <div className="image-1">
                <img src={imgFighter} height={600} width={800} alt="Cyfonii" />
              </div>

              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner4;
