import React from "react";
import logo2 from "../assets/img/logo/new-respectLogo2.png";
import { NavLink, Outlet } from "react-router-dom";

const drawerWidth = 240;
const array = [
  { img: "🏠", name: "User Dashboard", link: "/user" },
  { img: "🏡", name: "Home", link: "/" },
];

const UserParent = () => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => setOpen(!open);

  const styledActiveLink = ({ isActive }) => ({
    textDecoration: "none",
    padding: "0px 5px",
    color: isActive ? "black" : "white",
    background: isActive ? "white" : "#1F223E",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: isActive ? "600" : "400",
    width: "100%",
    height: "40px",
    borderLeft: isActive ? "5px solid #484848" : "none",
    borderRadius: "0px",
  });

  return (
    <div style={{ display: "flex", background: "#EBEDEF" }}>
      {/* AppBar */}
      <div
        style={{
          width: "100%",
          background: "#1F223E",
          color: "#fff",
          height: "64px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 20px",
          position: "fixed",
          zIndex: 2,
        }}
      // className="ban"

      >
        <button
          style={{
            background: "none",
            color: "#fff",
            border: "none",
            cursor: "pointer",
          }}
          onClick={handleDrawerToggle}
        >
          ☰
        </button>
        <h1  style={{ margin: 0, fontWeight: 600,fontSize:'20px' }}>User Dashboard</h1>
      </div>

      {/* Drawer */}
      <div
        style={{
          width: open ? `${drawerWidth}px` : "60px",
          background: "#1F223E",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          transition: "width 0.3s",
          zIndex: 1,
        }}
      >
        <div
          style={{
            height: "64px",
            background: "#CB9D47",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={logo2}
              alt="logo"
              style={{ width: "40px", height: "40px", marginRight: "10px" }}
            />
            {open && <h3 style={{ color: "#fff", fontWeight: "bold" }}>Respect</h3>}
          </div>
          {open && (
            <button
              style={{
                background: "none",
                border: "none",
                color: "white",
                cursor: "pointer",
              }}
              onClick={handleDrawerToggle}
            >
              &#60;
            </button>
          )}
        </div>
        <nav
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: open ? "flex-start" : "center",
            padding: open ? "10px" : "0",
          }}
        >
          {array.map((item, index) => (
            <NavLink key={index} to={item.link} style={styledActiveLink}>
              <span style={{ marginRight: open ? "10px" : "0" }}>{item.img}</span>
              {open && item.name}
            </NavLink>
          ))}
        </nav>
      </div>

      {/* Main Content */}
      <main
        style={{
          marginLeft: open ? `${drawerWidth}px` : "60px",
          padding: "72px 20px",
          background: "#EBEDEF",
          minHeight: "100vh",
          flexGrow: 1,
          transition: "margin-left 0.3s",
        }}
      >
        <Outlet />
      </main>
      <style>
        {
          `
           @media (max-width: 768px) {
           
          }
          `
        }
      </style>
    </div>
  );
};

export default UserParent;
