import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

import data from '../../assets/fake-data/dataPartner';
import logo from "../../assets/images/logo/brand-01.png"
import logo2 from "../../assets/images/logo/brand-05.png"
import logoss from "../../assets/images/logoss.png"
import logo3 from "../../assets/images/MASTKD.png"

function Partner(props) {
    return (
        <div className="brands block-text center mb-20"    data-aos="fade-up"  data-aos-duration="2000">
            <h6 className="sub-heading"><span>Our Partners</span></h6>

           <div>
                        {/* Use anchor tag for external URLs */}
                       
                        <a href={`https://www.MasTaekwondo.com`}   target="_blank" style={{margin:'10px'}} rel="noopener noreferrer">
                            <img  src={logoss} alt="Cyfonii" width="250px" />
                        </a>
                        
                        <a href={`https://www.KombatTaekwondo.com`}   target="_blank"  style={{margin:'10px'}}  rel="noopener noreferrer">
                            <img  src={logo2} alt="Cyfonii"  />
                        </a>
                        </div>
                   
        </div>
    );
}

export default Partner;




// import React from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/scss';
// import 'swiper/scss/navigation';
// import 'swiper/scss/pagination';

// import data from '../../assets/fake-data/dataPartner';

// function Partner(props) {
//     return (
//         <div className="brands block-text center mb-20" style={{width:'100%',display:'flex',justifyConten:'center'}}   data-aos="fade-up"  data-aos-duration="2000">
//             <h6 className="sub-heading"><span>Our Partners</span></h6>

//             <Swiper  
//                 className="brands-swiper"
                
//                 spaceBetween={30}
//                 breakpoints={{
//                     0: {
//                         slidesPerView: 2,
//                     },
//                     500: {
//                         slidesPerView: 3,
//                     },
//                     768: {
//                         slidesPerView: 4,
//                     },
//                     991: {
//                         slidesPerView: 5,
//                     },
//                 }}
//                 loop={true}
//             >
//                 {data.map(idx => (
//                     <SwiperSlide  key={idx.id}>
//                         {/* Use anchor tag for external URLs */}
                       
//                         <a  href={`https://${idx.url}`}  target="_blank" rel="noopener noreferrer">
//                             <img  src={idx.img} alt="Cyfonii" />
//                         </a>
                        
//                     </SwiperSlide>
//                 ))} 
                 
//             </Swiper>
//         </div>
//     );
// }

// export default Partner;
