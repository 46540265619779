import { React, useState, useEffect } from "react";
import AOS from "aos";
import { Route, Routes, Navigate,useNavigate } from "react-router-dom";
import Popup from "./components/Popup/Popup"; // Import Popup component
import routes from "./pages";
import Page404 from "./pages/404";
import Header from "./components/header/Header";
import { useNetwork, useSwitchNetwork } from "wagmi";
import { useLocation } from "react-router-dom";
import AdminParent from "./Admin/AdminParent";
import AdminDashboard from "./Admin/AdminDashboard";
import UserDashboard from "./User/UserDashboard";
import UserParent from "./User/UserParent";
import { Colors } from "chart.js";
import LoginBanner from "./components/banner/LoginBanner";

function Login({ onLogin  }) {
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // Use navigate for programmatic navigation

  const handleSubmit = (e) => {
    e.preventDefault();
    const correctPassword = "RespectAdmin"; // Replace with a secure password or environment variable
    if (password === correctPassword) {
      onLogin(true); // Set authentication state
      navigate("/admin"); // Redirect to /admin after successful login
    } else {
      alert("Incorrect Password!");
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{alignItems:"center",textAlign:"center",display:"flex",justifyContent:"center",justifyItems:"center"}}>
      
      <LoginBanner password={password} setPassword={setPassword} />
    </form>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const { pathname } = useLocation();
  const { chain } = useNetwork();
  const { chains, switchNetwork } = useSwitchNetwork();

  useEffect(() => {
    window.scrollTo(0, 0);

    try {
      if (chain?.id === 8453) {
        return;
      } else {
        switchNetwork(8453);
      }
    } catch (error) {
      console.log("error", error);
    }
  }, [chain?.id, chains, pathname, switchNetwork]);

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <>
      <Popup isVisible={isPopupVisible} onClose={togglePopup} />
      <Routes>
        {routes.map((data, idx) => (
          <Route key={idx} path={data.path} element={data.component} exact />
        ))}
        <Route path="*" element={<Page404 />} />

        {/* Protect /admin route */}
        <Route
          path="/admin/*"
          element={
            isAuthenticated ? (
              <AdminParent />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        >
          <Route index element={<AdminDashboard />} />
        </Route>

        <Route path="/login" element={<Login onLogin={setIsAuthenticated} />} />

        <Route exact path="/user" element={<UserParent />}>
          <Route index element={<UserDashboard />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
