
import icon1 from '../images/icon/quote.png'
import avt1 from '../images/layouts/avt-02.png'


const dataTestimonials = [

    {
        id: 1,
        icon: icon1,
        text: '“ Respect Token represents the values we instill in every athlete—discipline, respect, and excellence. As an Olympic coach and former athlete, I’ve seen how Taekwondo unites people across the globe, and this initiative is a powerful step forward in building an even stronger, more connected community. Respect Token isn’t just about innovation; it’s about honoring the spirit of martial arts and giving back to the practitioners, fans, and supporters who make our sport extraordinary.”',
        avt: avt1,
        name: ' Gabriel Taraburelli',
        position: ' 2000, 2012, 2020 & 2024 - Olympic Coach & Olympian'
    },
    // {
    //     id: 2,
    //     icon: icon1,
    //     text: '“ Curabitur eu est feugiat quam feugiat tristiqueee non vel erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque congue, nunc nec suscipit fringilla, augue ligula eleifend velit. “',
    //     avt: avt1,
    //     name: 'Jacob Jones',
    //     position: 'Chief Product Officer'
    // },
    // {
    //     id: 3,
    //     icon: icon1,
    //     text: '“ Curabitur eu est feugiat quam feugiat tristique non vel erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque congue, nunc nec suscipit fringilla, augue ligula eleifend velit. “',
    //     avt: avt1,
    //     name: 'Jacob Jones',
    //     position: 'Chief Product Officer'
    // },

]

export default dataTestimonials;