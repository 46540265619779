import React from "react";
import logo2 from "../assets/img/logo/new-respectLogo2.png";
import { NavLink, Outlet } from "react-router-dom";

const drawerWidth = 240;
const array = [
  {
    img: "🏠", // Replace with an icon if needed
    name: "Admin Dashboard",
    link: "/admin",
  },
  {
    img: "🏡", // Replace with an icon if needed
    name: "Home",
    link: "/",
  },
];

const AdminParent = () => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen((prevState) => !prevState); // Toggles the open state
    console.log("Drawer state toggled:", !open);
  };

  const handleDrawerClose = () => {
    setOpen(false); // Explicitly close the drawer
    console.log("Drawer closed");
  };

  const styledActiveLink = ({ isActive }) => ({
    textDecoration: "none",
    textTransform: "capitalize",
    padding: "0px 5px",
    borderRadius: "0px",
    color: isActive ? "black" : "white",
    background: isActive ? "white" : "#1F223E",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: isActive ? "600" : "400",
    width: "100%",
    height: "40px",
    borderLeft: isActive ? "5px solid #484848" : "none",
    display: "flex",
  });

  return (
    <div style={{ display: "flex", background: "#EBEDEF" }}>
      {/* AppBar */}
      <div
        style={{
          width: "100%",
          background: "#1F223E",
          color: "#000",
          height: "64px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 20px",
          position: "fixed",
          zIndex: 1,
        }}
      >
        <button
          style={{
            background: "#1F223E",
            color: "#fff",
            height: "64px",
            width: "67.5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "none",
            cursor: "pointer",
          }}
          onClick={handleDrawerToggle} // Toggle open/close
        >
          ☰
        </button>
        <h1 style={{ margin: 0, fontWeight: 600 }}>Admin Dashboard</h1>
      </div>

      {/* Drawer */}
      <div
        style={{
          width: open ? `${drawerWidth}px` : "60px",
          background: "#1F223E",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          transition: "width 0.3s",
        }}
      >
        <div
          style={{
            height: "64px",
            background: "#CB9D47",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={logo2}
              alt="logo"
              style={{ width: "40px", height: "40px", marginRight: "10px" }}
            />
            {open && (
              <h3 style={{ color: "#fff", fontWeight: "bold" }}>Respect</h3>
            )}
          </div>
          <button
            style={{
              background: "none",
              border: "none",
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={handleDrawerClose} // Close the drawer
          >
            &lt;
          </button>
        </div>
        <nav
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: open ? "flex-start" : "center",
          }}
        >
          {array.map((item, index) => (
            <NavLink
              key={index}
              to={item.link}
              style={styledActiveLink}
            >
              <span style={{ marginRight: open ? "10px" : "0" }}>
                {item.img}
              </span>
              {open && item.name}
            </NavLink>
          ))}
        </nav>
      </div>

      {/* Main Content */}
      <main
        style={{
          marginLeft: open ? `${drawerWidth}px` : "60px",
          padding: "72px 20px",
          background: "#EBEDEF",
          minHeight: "100vh",
          flexGrow: 1,
          transition: "margin-left 0.3s",
        }}
      >
        <Outlet />
      </main>
    </div>
  );
};

export default AdminParent;
