import React, { useState, useEffect } from "react";

import {  NavLink } from "react-router-dom";
import menus from "../../pages/menu";
import meta from "../../assets/images/logo/meta.png"
import walletsd from "../../assets/images/logo/wallet.png"
import trust from "../../assets/images/logo/trust.png"
import coin from "../../assets/images/logo/coin.png"
import logo from "../../assets/images/logo/logo.png";
import whitepaper from "../../assets/whitepaper/respectwhitepaper.pdf"
import "./styles.scss";
import usdtlogo from "../../assets/img/logo/usdtlogo.png";
import Ethereumlogo from "../../assets/img/logo/Ethereumlogo.png";
import { ProgressBar } from 'react-bootstrap';
// hello 
import { useCallback } from "react";
import { erc20ABI, useAccount, useConnect,useBalance } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { waitForTransaction } from "@wagmi/core";
import { Link } from "react-router-dom";
import img from "../../assets/images/layouts/deco.png";
import imgFighter from "../../assets/images/layouts/header-image.png";
import user from "../../assets/img/images/user.jpg"
import {
  preSaleReadFunction,
  preSaleWriteFunction,
  usdtReadFunction,
  usdtWriteFunction,
} from "../../ConnectivityAssets/hooks";
import { formatUnits, parseUnits } from "@ethersproject/units";
import {
  preSaleAddress,
  usdtAddress,
} from "../../ConnectivityAssets/environment";
import { writeContract, readContract } from "@wagmi/core";
import { Modal, Button } from 'react-bootstrap';
const initialTimer = 1296000;
const Header = () => {
   const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
    };
    
  const [countdownValues, setCountdownValues] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const [timer, setTimer] = useState(initialTimer);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
        updateCountdownValues(timer - 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1000); // Update every 1000 milliseconds (1 second)

    return () => clearInterval(intervalId);
  }, [timer]);

  const updateCountdownValues = (timeLeft) => {
    const days = Math.floor(timeLeft / (60 * 60 * 24));
    const hours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((timeLeft % (60 * 60)) / 60);
    const seconds = timeLeft % 60;

    setCountdownValues({ days, hours, minutes, seconds });
  };

  useEffect(() => {
    updateCountdownValues(timer);
  }, []); // Initialize countdown values on mount

  const handleOpenUsers = () => {
    // Replace 'example.pdf' with the path to your PDF file
    // Create a new window (popup)dd
   window.open("https://app.respecttoken.com", "_blank");

    // Set the content of the new window to display the PDF inside an iframe
   // Close the document stream to render it
  };

  
  const [currentPrice, setCurrentPrice] = useState(0);
  const [nextPrice, setNextPrice] = useState(0);
  const [sold, setSold] = useState(0);
  const [usdtToRaised, setUsdtToRaised] = useState(0);
  const [usdtTotalRaised, setUsdtTotalRaised] = useState(0);
  const [transectionData, setTransectionData] = useState(null);
  const [amount, setAmount] = useState(0);
  const [presaleId, setPresaleId] = useState(1);
  const [youWillGet, setYouWillGet] = useState(0);
  const [minimumButToken, setMinimumBuyToken] = useState(0);
  const [canbuy, setCanBuy] = useState(null);
  const [Allownce, setAllownce] = useState(0);
  const [activeCurrencry, setActiveCurrency] = useState("ETH");
  const [loading, setLoading] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showIneligibilityModal, setShowIneligibilityModal] = useState(false);
 const [formattedBalance,setFormattedBalance] = useState("0.00")
  const handleVerifyClose = () => setShowVerifyModal(false);
  const handleVerifyShow = () => setShowVerifyModal(true);

  const handleConfirmClose = () =>{
    setShowConfirmationModal(false);
    setIsChecked(false)
  }
  const handleConfirmShow = () =>{ setShowConfirmationModal(true)
    setShowVerifyModal(false)};

  const handleIneligibilityClose = () => setShowIneligibilityModal(false);
  const handleIneligibilityShow = () => {
    setShowIneligibilityModal(true) 
    setShowVerifyModal(false)
  };
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showToast = (msg, type) => {
    return setAlertState({
      open: true,
      message: msg,
      severity: type,
    });
  };
  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const { data, isError, isLoading } = useBalance({
    address: address,
  });
  useEffect(() => {
    if (data?.formatted && !isNaN(Number(data?.formatted))) {
      setFormattedBalance(Number(data?.formatted).toFixed(2));
    }
  }, [data?.formatted]);
  const connectionHandler = React.useCallback(() => {
    setIsChecked(false)
    setShowConfirmationModal(false)
    if (address) {
      return open();
     
    }
    open();
  }, [address, open]);


  const init = useCallback(async () => {
    try {
      // setLoading(true);

      const id = await preSaleReadFunction("presaleId");

      setPresaleId(id);
      const presaleData = await preSaleReadFunction("presale", [id]);

      const cPrice = 1 / Number(formatUnits(presaleData[2], 18));
      setCurrentPrice(cPrice);

      const nPrice = 1 / Number(formatUnits(presaleData[3], 18));
      setNextPrice(nPrice);

      const soldtoken = Number(formatUnits(presaleData[4], 18));
      setSold(soldtoken);

      const usdtRaise = Number(formatUnits(presaleData[7], 6));
      setUsdtToRaised(usdtRaise);

      const totalRaised = Number(formatUnits(presaleData[6], 6));
      setUsdtTotalRaised(totalRaised);

      // Minimum Buy token
      const minumBuyT = await preSaleReadFunction("MinTokenTobuy");
      setMinimumBuyToken(Number(formatUnits(minumBuyT, 18)));
      setPresaleId(id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      /* empty */
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const fetchAllowance = useCallback(async () => {
    try {
      const allow = await readContract({
        address: usdtAddress,
        abi: erc20ABI,
        functionName: "allowance",
        args: [address, preSaleAddress],
      });

      setAllownce(formatUnits(allow, 6));
    } catch (error) {
      console.error("Failed to fetch allowance", error);
    }
  }, [address]);

  useEffect(() => {
    const updateCanBuy = () => {
      if (Number(Allownce) >= Number(amount)) {
        setCanBuy(true);
      } else {
        setCanBuy(false);
      }
    };
    updateCanBuy();
  }, [Allownce, amount]);

  // Onchange Function
  const onAmountChange = async (e) => {
    try {
      const inputAmount = e.target.value;
      setAmount(inputAmount);

      if (
        inputAmount === "" ||
        inputAmount === "0" ||
        isNaN(Number(inputAmount))
      ) {
        setYouWillGet(0);
        setLoading(false);
        return;
      }

      if (activeCurrencry === "ETH") {
        const ethToToken = await preSaleReadFunction("ethToTokens", [
          presaleId,
          parseUnits(inputAmount).toString(),
        ]);

        setYouWillGet(Number(formatUnits(ethToToken, 18)));
      } else {
        const usdtToToken = await preSaleReadFunction("usdtToTokens", [
          presaleId,
          parseUnits(inputAmount, 6).toString(),
        ]);

        setYouWillGet(Number(formatUnits(usdtToToken, 18)));
      }
    } catch (error) {
      setLoading(false);
      showToast("Error during Transection !", "error");
      console.log("Error ....", error);
    }
  };

  const approveUSDT = async () => {
    setLoading(true);
    try {
      const { hash } = await writeContract({
        address: usdtAddress,
        abi: erc20ABI,
        functionName: "approve",
        args: [preSaleAddress, parseUnits(amount, 6).toString()],
      });
      const receipt = await waitForTransaction({ hash });
      if (hash && receipt) {
        await fetchAllowance();
        showToast(`${amount} USDT Approve`);
      } else {
        throw new Error("Approval transaction failed");
      }
    } catch (error) {
      console.error("Approval failed", error);
      showToast("Approval failed: " + error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (address) {
      fetchAllowance();
    }
  }, [address, fetchAllowance]);

  // Add Transection Function

  // Function to save data to Google Sheet
  const saveData = async (responce) => {
    console.log("respnce.....", responce);
    const date = new Date().toISOString();
    const data = [
      date,
      address,
      responce.transactionHash,
      amount,
      activeCurrencry,
      youWillGet,
    ];
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycby8bpalfahYfZDKHrmzSVmbFrV7ZhDbEBaQa2Y1uTVEJINxqpyimaGLc0Pw0_LfgdgA7A/exec",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      const result = await response.text();
      console.log(result);
      setAmount(0);
      setYouWillGet(0);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  // Buy Tokens

  const buyHandler = async (e) => {
    e.preventDefault();
    // validation
    if (isNaN(Number(amount))) {
      showToast("Invalid Input !", "error");
      return;
    }
    if (Number(youWillGet) < minimumButToken) {
      showToast(
        `The minimum ammount to buy  is ${minimumButToken}RESPECT.`,
        "error"
      );
      return;
    }
    try {
      setLoading(true);
      // buy with ETH

      if (activeCurrencry === "ETH") {
        // Call the function to buy with ETH
        const res = await preSaleWriteFunction(
          "buyWithEth",
          [],
          parseUnits(amount, 18).toString()
        );
        saveData(res);
        setAmount(0);
        setYouWillGet(0);
        setLoading(false);
        // setTransectionhash(res);
        // setOpenD(true);
      } else {
        // buy with USDT

        const res = await preSaleWriteFunction("buyWithUSDT", [
          parseUnits(amount, 6).toString(),
        ]);
        saveData(res);
        setAmount(0);
        setYouWillGet(0);

        // setTransectionhash(res);
        // setOpenD(true);
      }

      init();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      showToast(error.message, "error");
      console.log("Error ...", error);
    }

    /// buy start

  };
 
  const progress = (usdtToRaised / usdtTotalRaised) * 100;

  const handleOpenPdf = () => {
    // Replace 'example.pdf' with the path to your PDF file
    // Create a new window (popup)
    const newWindow = window.open("", "_blank");

    // Set the content of the new window to display the PDF inside an iframe
    newWindow.document.write(`
      <html>
        <head><title>View Whitepaper</title></head>
        <body style="margin:0; display: flex; justify-content: center; align-items: center; height: 100vh; background: #f0f0f0;">
          <iframe src="${whitepaper}" width="100%" height="100%" style="border: none;"></iframe>
        </body>
      </html>
    `);
    newWindow.document.close(); // Close the document stream to render it
  };
  const handleOpenReport = () => {
    // Replace 'example.pdf' with the path to your PDF file
    // Create a new window (popup)
   window.open("https://github.com/TokenMinds-co/Respect_Token_Audit_Report/blob/main/Respect%20Token%20Audit%20Report%20V1.1.pdf", "_blank");

    // Set the content of the new window to display the PDF inside an iframe
   // Close the document stream to render it
  };
 
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
    return () => {
      setScroll({});
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
    <header id="header_main" className={`header ${scroll ? "is-fixed" : ""}`}>
      <div className="container big">
        <div className="row">
          <div className="col-12">
            <div className="header__body">
              <div className="header__logo">
                <Link to="/">
                  <img id="site-logo" width={70} src={logo} alt="respect" />
                </Link>
              </div>

              {/* Navigation menu sectionnnn */}
              <div className="header__right">
                <nav
                  id="main-nav"
                  className={`main-nav ${menuActive ? "active" : ""}`}  // Toggle 'active' class for mobile view
                >
                  <ul id="menu-primary-menu" className="menu">
                    {menus.map((data, idx) => (
                      <li
                        key={idx}
                        onClick={() => handleDropdown(idx)}  // Toggle active dropdown on click
                        className={`menu-item ${
                          data.namesub ? "menu-item-has-children" : ""
                        } ${activeIndex === idx ? "active" : ""}`}
                      >
                        {/* Using NavLink to auto-apply 'active' class when the route matches */}
                        <NavLink
                          to={data.links}  // Link to the route defined in the menu data
                          activeClassName="active"  // This will highlight the active link
                        >
                          {data.name}  {/* The name of the menu item */}
                        </NavLink>
                        
                        {/* Submenu for items that have children */}
                        {data.namesub && (
                          <ul className="sub-menu">
                            {data.namesub.map((submenu) => (
                              <li key={submenu.id} className="menu-item">
                                <NavLink
                                  to={submenu.links}  // Submenu link
                                  activeClassName="active"  // Highlight the active submenu item
                                >
                                  {submenu.sub}  {/* Submenu name */}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                    
                    {/* Menu item to open Whitepaper PDFf */}
                    <li className="whitepaper" onClick={handleOpenPdf}>
                      <span>Whitepaper</span>
                    </li>
                    
                    {/* Menu item to open Audit Report PDFff */}
                    <li className="whitepaper" onClick={handleOpenReport}>
                      <span>Audit Report</span>
                    </li>
                    <li style={{padding:'none'}}>
                      {!address ? <Link onClick={handleConfirmShow} className="action-btn" >
                  <span>Buy Now</span>
                </Link>:
               
                <Link to="/user">
                <span className="action-btn" style={{cursor:":pointer"}}> <img src={user} style={{height:'40px',width:'40px',background:'white',borderRadius:'100px',cursor:'pointer',marginRight:'5px'}}/> {`${address.slice(0, 6)}...${address.slice(-4)}`} | {formattedBalance} </span>
                </Link>
                }
                    </li>
                  </ul>
                </nav>
                

                {/* Mobile navigation button to toggle the menu */}
                <div
                  className={`mobile-button ${menuActive ? "active" : ""}`}
                  onClick={handleMenuActive}  // Toggle menu visibility on mobile view
                >
                  <span></span>
                </div>
              </div>
              <div className="header__action">
                {/* <Link to="#" className="search-btn pr-3">
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="9.7659"
                      cy="9.76639"
                      r="8.98856"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.0176 16.4849L19.5416 19.9997"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link> */}

               
               
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </header>
  <Modal
        show={showVerifyModal}
        onHide={handleVerifyClose}
        size="lg"
        // Setting the width to 80% of the screen
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: '15px', color: 'black',textAlign:'center',margin:'auto' }}>
            <span style={{textAlign:'center'}}>            MANDATORY PARTICIPATION AGREEMENT AND DISCLAIMER </span>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 'small', color: 'black', maxHeight: '70vh', // Max height to prevent body from overflowing
            overflowY: 'auto' }}>
          <p>
            <strong>IMPORTANT LEGAL NOTICE</strong><br />
            This Participation Declaration and Disclaimer governs access to, adopters, or participation in activities 
            related to the RESPECT Token. By proceeding, you acknowledge and agree to the terms outlined below. 
            Failure to comply may result in termination of participation and/or legal consequences.
          </p>
          <br/>
          <p>
            <strong>1. Restricted Participation</strong><br />
            Participation in the RESPECT Token is expressly prohibited for: Individuals or entities located in, resident 
            in, or otherwise subject to the jurisdiction of the United States of America or its territories and 
            possessions (the "U.S."); Individuals or entities in jurisdictions where cryptocurrency participation is 
            prohibited or restricted by applicable law. <br/>
            Restricted persons include but are not limited to: U.S. citizens, regardless of residency; U.S. residents, 
            including green card holders or tax residents; Entities incorporated or organized under U.S. law; Persons 
            accessing or transacting related to the RESPECT Token from within the U.S. or restricted jurisdictions.
          <br/>
          </p>
          <br/>

          <p>
            <strong>2. Declarations by Participants</strong><br />
            By participating, you represent, warrant, and agree that:
            <ul>
              <li><strong>Non-U.S. Person:</strong> You are not a U.S. Person as defined by Regulation S under the U.S. Securities Act of 1933, as amended.</li>
              <li><strong>Jurisdictional Compliance:</strong> Your participation complies with all applicable laws and regulations in your jurisdiction, and you are solely responsible for ensuring such compliance.</li>
              <li><strong>No Regulatory Protections:</strong> You understand that the RESPECT Token is not registered as a security, financial product, or investment under Singapore law or any other jurisdiction.</li>
              <li><strong>Truthful Information:</strong> All information you provide, including residency and citizenship, is true, accurate, and complete.</li>
              <li><strong>Voluntary Participation:</strong> Your participation is entirely voluntary and does not rely on guarantees, warranties, or representations regarding future value, returns, or usability of the RESPECT Token.</li>
            </ul>
          </p>
          <br/>

          <p>
            <strong>3. Limitations and Waivers</strong><br />
            <ul>
              <li><strong>Non-Investment Token:</strong> The RESPECT Token is not an investment product and does not provide ownership, equity, or profit-sharing rights in any entity or organization.</li>
              <li><strong>No Liability:</strong> The issuer and associated parties are not liable for any losses, claims, damages, or liabilities arising from:
                <ul>
                  <li>Changes in legal or regulatory conditions;</li>
                  <li>Loss of token value or usability;</li>
                  <li>Misrepresentations made by participants regarding eligibility.</li>
                </ul>
              </li>
              <li><strong>Indemnification:</strong> You agree to indemnify and hold harmless the issuer, its affiliates, and agents against any claims or damages resulting from your participation or breach of these terms.</li>
            </ul>
          </p>
          <br/>

          <p>
            <strong>4. Risks and Disclaimers</strong><br />
            Participation in the RESPECT Token involves significant risks, including but not limited to:
            <ul>
              <li>Loss of funds due to token devaluation, theft, or technological vulnerabilities;</li>
              <li>Regulatory changes that could impact the legal status, usability, or value of the token;</li>
              <li>Irrevocable transactions on the blockchain.</li>
            </ul>
            Participants are solely responsible for evaluating these risks and accept them fully.
          </p>
          <br/>

          <p>
            <strong>5. Prohibited Conduct</strong><br />
            Participants agree not to:
            <ul>
              <li>Circumvent restrictions through intermediaries, false declarations, or deceptive practices;</li>
              <li>Engage in unlawful activities, including but not limited to money laundering, fraud, or terrorist financing, through the RESPECT Token;</li>
              <li>Resell or transfer tokens to restricted persons or jurisdictions.</li>
            </ul>
          </p>
          <br/>

          <p>
            <strong>6. Governing Law and Dispute Resolution</strong><br />
            <ul>
              <li><strong>Governing Law:</strong> This disclaimer is governed by the laws of the Republic of Singapore.</li>
              <li><strong>Dispute Resolution:</strong> All disputes arising from participation will be resolved through arbitration in Singapore under the rules of the Singapore International Arbitration Centre (SIAC).</li>
            </ul>
          </p>
          <br/>

          <p>
            <strong>7. Acknowledgment and Agreement</strong><br />
            By participating in the RESPECT Token, you confirm and agree to the following:
            <ul>
              <li>I have read, understood, and accepted the terms of this disclaimer.</li>
              <li>I am not a U.S. citizen, U.S. resident, or otherwise subject to U.S. jurisdiction.</li>
              <li>I understand that my participation is void where prohibited by law.</li>
              <li>I accept full responsibility for compliance with the laws of my jurisdiction.</li>
            </ul>
          </p>
          <br/>

          {/* <div style={{textAlign:'center'}}>
            <strong>PLEASE CONFIRM YOUR ELIGIBILITY TO PROCEED:</strong><br />
            <br />
            <Button variant="success" size="sm" onClick={handleConfirmShow}>
            I MEET ELIGIBILITY
            </Button>{' '}
            <Button variant="danger" size="sm" onClick={handleIneligibilityShow}>
              I DO NOT MEET  ELIGIBILITY 
            </Button>
          </div> */}
        </Modal.Body>
      </Modal>

      {/* Confirmation Modal */}
      <Modal show={showConfirmationModal} size="lg" onHide={handleConfirmClose}>
  <Modal.Header style={{ borderBottom: 'none' }} closeButton closeVariant="black">
    <Modal.Title
      style={{
        fontSize: '18px',
        textAlign: 'center',
        margin: 'auto',
        width: '100%',
        fontWeight: 'bold',
        color: 'black',
      }}
    >
      CONNECT WALLET
    </Modal.Title>
  </Modal.Header>
  <Modal.Body style={{ color: 'black', padding: '20px' }}>
    <p
      style={{
        fontSize: '14px',
        textAlign: 'center',
        marginBottom: '20px',
        lineHeight: '1.5',
      }}
    >
      By connecting a wallet, you acknowledge that you have read, understood, and agree to{' '}
      <a onClick={handleVerifyShow} target="_blank" rel="noopener noreferrer" style={{ color: '#007bff', textDecoration: 'underline' }}>
        Respect Token Terms of Service
      </a>.
    </p>
    <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
      <div style={{ display: 'flex', alignItems: 'flex-start', gap: '10px' }}>
        <input
          type="checkbox"
          id="termsCheck"
          style={{ marginTop: '2px' }}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="termsCheck" style={{ fontSize: '14px', lineHeight: '1.5' }}>
          I have read and agree to terms and conditions.
        </label>
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '30px',
        alignItems: 'center',
      }}
    >
      {/* MetaMask */}
      <div
        style={{
          textAlign: 'center',
          cursor: isChecked ? 'pointer' : 'not-allowed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={isChecked ? connectionHandler : null}
      >
        <img
          src={meta}
          alt="MetaMask"
          style={{
            width: '50px',
            height: '50px',
            marginBottom: '8px',
            opacity: isChecked ? 1 : 0.5, // Adjust opacity when disabled
          }}
        />
        <p style={{ fontSize: '10px', fontWeight: 'bold', margin: 0 }}>
          MetaMask
        </p>
      </div>

      {/* Trust Wallet */}
      <div
        style={{
          textAlign: 'center',
          cursor: isChecked ? 'pointer' : 'not-allowed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={isChecked ? connectionHandler : null}
      >
        <img
          src={trust}
          alt="Trust Wallet"
          style={{
            width: '50px',
            height: '50px',
            marginBottom: '8px',
            opacity: isChecked ? 1 : 0.5, // Adjust opacity when disabled
          }}
        />
        <p style={{ fontSize: '10px', fontWeight: 'bold', margin: 0 }}>
          Trust Wallet
        </p>
      </div>

      {/* Coinbase Wallet */}
      <div
        style={{
          textAlign: 'center',
          cursor: isChecked ? 'pointer' : 'not-allowed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={isChecked ? connectionHandler : null}
      >
        <img
          src={coin}
          alt="Coinbase Wallet"
          style={{
            width: '50px',
            height: '50px',
            marginBottom: '8px',
            opacity: isChecked ? 1 : 0.5, // Adjust opacity when disabled
          }}
        />
        <p style={{ fontSize: '10px', fontWeight: 'bold', margin: 0 }}>
          Coinbase Wallet
        </p>
      </div>

      {/* WalletConnect */}
      <div
        style={{
          textAlign: 'center',
          cursor: isChecked ? 'pointer' : 'not-allowed',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClick={isChecked ? connectionHandler : null}
      >
        <img
          src={walletsd}
          alt="WalletConnect"
          style={{
            width: '50px',
            height: '50px',
            marginBottom: '8px',
            opacity: isChecked ? 1 : 0.5, // Adjust opacity when disabled
          }}
        />
        <p style={{ fontSize: '10px', fontWeight: 'bold', margin: 0 }}>
          WalletConnect
        </p>
      </div>
    </div>
  </Modal.Body>
  <Modal.Footer style={{ justifyContent: 'center', borderTop: 'none' }} />
</Modal>
      {/* Ineligibility Modal */}
      <Modal show={showIneligibilityModal} size="lg" onHide={handleIneligibilityClose}>
        <Modal.Header closeButton>
          <Modal.Title  style={{ fontSize: '15px', color: 'black',textAlign:'center',margin:'auto',width:'100%' }}> <span style={{textAlign:'center'}}>    Notice of Ineligibility</span></Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 'small', color: 'black' }}>
        We regret to inform you that, based on your response, you are not eligible to participate in the RESPECT Token purchase or related activities.
<br/>
Participation in the RESPECT Token is restricted to individuals and entities that meet the eligibility requirements outlined in the Participation Declaration and Disclaimer. These requirements are in place to comply with applicable laws and regulations, including restrictions on participation from certain jurisdictions.
<br/>
Important:
Attempting to circumvent these restrictions by providing false or misleading information may result in legal consequences, including but not limited to disqualification from participation, forfeiture of tokens, or other actions as permitted by law.
<br/>
If you believe this determination was made in error, we recommend reviewing the eligibility requirements again or consulting legal counsel before proceeding further.
<br/>
We thank you for your understanding and cooperation.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleIneligibilityClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </>
    // <header className={`header ${scroll ? 'is-fixed' : ''}`}>
    //         <div className="tf-container">
    //             <div className="row">
    //                 <div className="col-md-12">
    //                     <div id="site-header-inner">
    //                         <div id="site-logo" className="clearfix">
    //                             <div id="site-logo-inner">
    //                                 <Link to="/" rel="home" className="main-logo">
    //                                     <img id="logo_header" className='logo-dark' src='{logodark}' alt="Binasea" />
    //                                     <img id="logo_header" className='logo-light' src='{logo}' alt="Binasea" />
    //                                 </Link>
    //                             </div>
    //                         </div>

    //                        <div className="header-center">
    //                         <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
    //                             <ul id="menu-primary-menu" className="menu">
    //                                 {
    //                                     menus.map((data,idx) => (
    //                                         <li key={idx} onClick={()=> handleDropdown(idx)} className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === idx ? 'active' : ''}`}

    //                                         >
    //                                             <Link to={data.links}>{data.name}</Link>
    //                                             {
    //                                                 data.namesub &&
    //                                                 <ul className="sub-menu">
    //                                                     {
    //                                                         data.namesub.map((submenu) => (
    //                                                             <li key={submenu.id} className="menu-item"><NavLink to={submenu.links}>{submenu.sub}</NavLink></li>
    //                                                         ))
    //                                                     }
    //                                                 </ul>
    //                                             }

    //                                         </li>
    //                                     ))
    //                                 }
    //                             </ul>
    //                         </nav>
    //                        </div>

    //                         <div className="header-right">

    //                             <DarkMode />

    //                             <Link to="/contact" className="tf-button discord"><i className="icon-fl-vt"></i><span>DISCORD</span></Link>
    //                             <Link to="#" className="tf-button connect" data-toggle="modal" data-target="#popup_bid"> <i className="icon-fl-wallet"></i><span>CONNECT</span></Link>
    //                             <div className={`mobile-button ${menuActive ? 'active' : ''}`} onClick={handleMenuActive}><span></span></div>
    //                         </div>

    //                     </div>
    //                 </div>
    //             </div>
    //         </div>

    //     </header>
  );
};

export default Header;
