import React, { useEffect, useState } from "react";
import axios from "axios";

const AdminTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiUrl =
    "https://script.google.com/macros/s/AKfycby8bpalfahYfZDKHrmzSVmbFrV7ZhDbEBaQa2Y1uTVEJINxqpyimaGLc0Pw0_LfgdgA7A/exec";

  const fetchAllData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(apiUrl);
      setData(response.data);
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <div style={{ overflowX: "auto", padding: "10px", maxWidth: "100%", margin: "0 auto" }}>
      <table
        style={{
          width: "100%",
          minWidth: "600px",
          borderCollapse: "collapse",
          tableLayout: "fixed",
        }}
      >
        <thead>
          <tr style={{ background: "#1F223E", color: "#fff", textAlign: "center" }}>
            {["Date", "Wallet Address", "Transaction Hash", "Amount of Tokens Paid", "Payment Token", "RESPECT Purchased"].map(
              (header, idx) => (
                <th
                  key={idx}
                  style={{
                    padding: "10px",
                    fontWeight: "bold",
                    border: "1px solid #F3F3F3",
                    fontSize: "14px",
                  }}
                >
                  {header}
                </th>
              )
            )}
          </tr>
        </thead>
        <tbody>
          {data?.tx?.length > 0 ? (
            data.tx.map((row, index) => (
              <tr
                key={index}
                style={{
                  textAlign: "center",
                  borderBottom: "1px solid #E5E7EB",
                }}
              >
                {row.map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    style={{
                      padding: "10px",
                      color: "#6D7280",
                      wordBreak: "break-word",
                      fontSize: "14px",
                    }}
                  >
                    {cellIndex === 0
                      ? new Date(cell).toLocaleString("en-US", {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          second: "numeric",
                          hour12: true,
                          timeZone: "UTC",
                        })
                      : cell}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" style={{ padding: "20px", textAlign: "center", color: "#6D7280" }}>
                {loading ? "Loading..." : "No Data"}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {error && <div style={{ color: "red", textAlign: "center", marginTop: "10px" }}>{error}</div>}
      <style jsx>{`
        @media (max-width: 768px) {
          th,
          td {
            font-size: 12px;
            padding: 8px;
          }
        }
        @media (max-width: 480px) {
          th,
          td {
            font-size: 10px;
            padding: 6px;
          }
          table {
            min-width: 480px;
          }
        }
      `}</style>
    </div>
  );
};

export default AdminTable;
