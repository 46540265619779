// export const tokenAddress = "0xc2Cf132Fbc1F6c99780E745D3b29E28dfa98f756"; //testnet
// export const tokenAddress = "0xB1AdcDc01BACEFc13316E0ed5f232829a5eBCED6"; // mainnet
export const tokenAddress = "0x9bd96Bc1db18E41084875C6d2B004f86d2DB75b6"; //testnet
export const usdtAddress = "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913"; // testnet

// export const usdtAddress = "0xb04678C0cac78245ddC7E728cB5120924dd18aB6"; // testnet
// export const usdtAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7"; // mainnet
export const preSaleAddress = "0x06813179Da876ac76976b8333b060eD12a247940";
// export const preSaleAddress = "0xe61F4D922C46ec83aC56d7F3ed371Ac6A17C24b9"; // testnet
// export const preSaleAddress = "0x4c1CB207d35DA490d20e6Ca65c9dAFCD95bF5b01"; //mainnett
