import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";

const UserTable = () => {
  const [walletData, setWalletData] = useState([]);
  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();

  const connectionHandler = useCallback(() => {
    open();
  }, [open]);

  const [error, setError] = useState(null);

  useEffect(() => {
    if (address) {
      const url = `https://script.google.com/macros/s/AKfycby8bpalfahYfZDKHrmzSVmbFrV7ZhDbEBaQa2Y1uTVEJINxqpyimaGLc0Pw0_LfgdgA7A/exec?id=${address}`;
      axios
        .get(url)
        .then((response) => setWalletData(response.data.tx))
        .catch((error) => setError(error.message));
    }
  }, [address]);

  return (
    <div style={{ padding: "16px", fontFamily: "Arial, sans-serif" }}>
      {address ? (
        <div
          style={{
            overflowX: "auto",
            maxHeight: "500px",
            width: "100%",
            border: "1px solid #E5E7EB",
            borderRadius: "8px",
          }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              minWidth: "800px",
            }}
          >
            <thead
              style={{
                background: "#1F223E",
                color: "#fff",
              }}
            >
              <tr>
                <th style={headerStyle}>Date</th>
                <th style={headerStyle}>Wallet Address</th>
                <th style={headerStyle}>Transaction Hash</th>
                <th style={headerStyle}>Amount of Tokens Paid</th>
                <th style={headerStyle}>Payment Token</th>
                <th style={headerStyle}>RESPECT Purchased</th>
              </tr>
            </thead>
            <tbody>
              {walletData?.length > 0 ? (
                walletData.map((row, index) => (
                  <tr key={index} style={rowStyle}>
                    <td style={cellStyle}>{new Date(row[0]).toLocaleString()}</td>
                    <td style={cellStyle}>{row[1]}</td>
                    <td style={cellStyle}>{row[2]}</td>
                    <td style={cellStyle}>{row[3]}</td>
                    <td style={cellStyle}>{row[4]}</td>
                    <td style={cellStyle}>{row[5]}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" style={noDataStyle}>
                    No Data Available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <button
            onClick={connectionHandler}
            style={{
              padding: "10px 20px",
              background: "#1F223E",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Connect Wallet
          </button>
        </div>
      )}
      
    </div>
  );
};

// Inline styles for reusability
const headerStyle = {
  padding: "12px",
  textAlign: "center",
  fontWeight: "bold",
  fontSize: "14px",
  borderBottom: "1px solid #E5E7EB",
};

const rowStyle = {
  background: "#F9FAFB",
  textAlign: "center",
};

const cellStyle = {
  padding: "8px",
  fontSize: "12px",
  borderBottom: "1px solid #E5E7EB",
  color: "#6B7280",
};

const noDataStyle = {
  padding: "16px",
  textAlign: "center",
  color: "#6B7280",
  fontSize: "14px",
};

export default UserTable;
