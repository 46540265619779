import React from "react";

const Toast = ({ alertState, setAlertState }) => {
  if (!alertState.open) return null;

  const handleClose = () => {
    setAlertState({ ...alertState, open: false });
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "300px",
        right: "20px",
        zIndex: 1000,
        backgroundColor:
          alertState.severity === "success"
            ? "green"
            : alertState.severity === "warning"
            ? "orange"
            : alertState.severity === "error"
            ? "red"
            : "blue",
        color: "white",
        padding: "10px 20px",
        borderRadius: "5px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
      }}
      onClick={handleClose}
    >
      {alertState.message}
    </div>
  );
};

export default Toast;
