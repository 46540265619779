import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../button/Button';
import img1 from '../../assets/images/layouts/new1.png.jpg'
import img2 from '../../assets/images/layouts/new2.jpg'
import img3 from '../../assets/images/layouts/news3.jpg'
import newimg from '../../assets/images/layouts/news4.jpeg'
import thumb1 from '../../assets/images/layouts/thumb1.png'
import thumb2 from '../../assets/images/layouts/thumb2.png'
import img4 from '../../assets/videos/sample-video-1.mp4'
import img5 from '../../assets/videos/sample-video-2.mp4'
import img6 from '../../assets/images/layouts/ben.png'
import img7 from '../../assets/images/layouts/coima.png'
function New() {
    const opennews = () => {
        // Replace 'example.pdf' with the path to your PDF file
        // Create a new window (popup)
       window.open("https://www.mastkd.com/2024/12/taekwondo-goes-digital-respect-token-to-revolutionize-the-sport-on-december-31/?fbclid=IwY2xjawHWHVZleHRuA2FlbQIxMAABHU2DVh--7F1hBknTgEic07h11czJoipPSg_gK3bp6de8h3-wxtraDuz1JA_aem_MEqdg3WVb4FLEGH6CPTlFw", "_blank");
    
        // Set the content of the new window to display the PDF inside an iframe
       // Close the document stream to render it
      };
    
    // News data
    const data = [
        {
            id: 1,
            img: img6,
            title: 'News 1',
            func:'',
            link:"https://www.benzinga.com/pressreleases/25/01/42868116/respect-token-a-new-era-for-taekwondo-fans-and-athletes",
           

        },
        {
            id: 2,
            img: img7,
            title: 'News 1',
            func:'',
            link:"https://coinmarketcap.com/community/articles/677e4af6faf5b43a1b3993da/ ",
         
        },
        {
            id: 3,
            img: img1,
            title: 'News 1',
            func:''
        },
        {
            id: 4,
            img: img2,
            title: 'News 2',
            func:''

        },
        {
            id: 5,
            img: img3,
            title: 'News 3',
        }, {
            id: 6,
            img: newimg,
            title: 'News 3',
            para:'🔴 B R E A K I N G   N E W S⭐️ TAEKWONDO GOES DIGITAL🤜🏻 Singapore, December 20, 2024 – MASTKD.com, the world’s largest Taekwondo media company, proudly supports the launch of the Respect Token, an innovative digital platform designed to transform the global Taekwondo landscape. The official launch event is set for December 31, 2024, in Singapore, marking a significant milestone for the sport and its international community.',
            

        },
    ];

    const links = [
        {link:"https://www.benzinga.com/pressreleases/25/01/42868116/respect-token-a-new-era-for-taekwondo-fans-and-athletes"},
        {link:"https://coinmarketcap.com/community/articles/677e4af6faf5b43a1b3993da/ "}
    ]

    // Video data
    const videos = [
        {
            id: 1,
            thumbnail: thumb2,
            src: img4,
            title: 'interview 1',
        },
        {
            id: 2,
            thumbnail: thumb1,
            src: img5,
            title: 'interview 2',
        },
    ];

    const [popupVideo, setPopupVideo] = useState(null); // State for popup video

    const openPopup = (videoSrc) => {
        setPopupVideo(videoSrc);
    };

    const closePopup = () => {
        setPopupVideo(null);
    };

      
    return (
        <section className="project s2">
            <div className="shape right"></div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="block-text center">
                            <h6 className="sub-heading"><span>NEWS</span></h6>
                            <h3 className="heading">CURRENT NEWS</h3>
                        </div>
                    </div>

                    {/* News Section */}
                    {data.map((item) => (
                        <div key={item.id} className="col-xl-4 col-md-6">
                            <div className="project-box">
                                <div className="image">
                                   
                                {item.link ? (
                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                        <img
                            src={item.img}
                            alt={item.title}
                            style={{
                                height: item.height || 'auto',
                                width: item.width || '100%',
                            }}
                        />
                    </a>
                ) : (
                    <img
                        src={item.img}
                        alt={item.title}
                        style={{
                            height: item.height || 'auto',
                            width: item.width || '100%',
                        }}
                    />
                )}              
                                    

                                </div>
                                <div className="content">
                                    <h5 style={{fontSize:'10px'}}>{item.para}</h5>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* Video Section */}
                    {videos.map((video) => (
                        <div key={video.id} className="col-xl-4 col-md-6">
                            <div className="project-box">
                                <div className="image">
                                    <img
                                        src={video.thumbnail}
                                        alt={video.title}
                                        onClick={() => openPopup(video.src)}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </div>
                                <div className="content">
                                    <h5>{video.title}</h5>
                                </div>
                            </div>
                        </div>
                    ))}

                    {/* View All Button */}
                    {/* <div className="button mt-0">
                        <Button title="View All NEWS" link="/news" />
                    </div> */}
                </div>
            </div>

            {/* Popup for Video */}
            {popupVideo && (
                <div className="video-popup" onClick={closePopup}>
                    <div className="" onClick={closePopup}></div>
                    <div className="popup-content">
                        <button className="close-button" style={{color:'black',fontSize:'2px'}}>
                           X
                        </button>
                        <video controls autoPlay>
    <source src={popupVideo} type="video/mp4" />
    {/* Your browser does not support the video tag. */}
</video>

                    </div>
                </div>
            )}
        </section>
    );
}

export default New;
