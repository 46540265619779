import React from 'react';

import Footer from '../components/footer/Footer';
// import PageTitle from '../components/pagetitle/PageTitle';

import New from '../components/news/new';
import dataItem from '../assets/fake-data/datanew';
import Header from '../components/header/Header';


function News(props) {
    return (
        <div className='wrapper'>
            <Header/>
            {/* <PageTitle title='NFTs' /> */}

            <New data={dataItem} />

            <Footer />
            
        </div>
    );
}

export default News;