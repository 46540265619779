import img1 from '../images/logo/brand-01.png';
import img2 from '../images/logo/brand-02.png';
import img3 from '../images/logo/brand-03.png';
import img4 from '../images/logo/brand-04.png';
import img5 from '../images/logo/brand-05.png';
import img6 from '../images/logo/brand-06.png';

const dataPartner = [
    {
        id: 1,
        img: img1,
        url: 'www.MasTaekwondo.com',  // Add URL for the first logo
    },
    // {
    //     id: 2,
    //     img: img2,
    //     url: 'https://example2.com',  // Add URL for the second logo
    // },
    // {
    //     id: 3,
    //     img: img3,
    //     url: 'https://example3.com',  // Add URL for the third logo
    // },
    // {
    //     id: 4,
    //     img: img4,
    //     url: 'https://example4.com',  // Add URL for the fourth logo
    // },
    {
        id: 5,
        img: img5,
        url: 'www.KombatTaekwondo.com',  // Add URL for the fifth logo
    },
    // {
    //     id: 6,
    //     img: img6,
    //     url: 'www.KombatTaekwondo.com',  // Add URL for the sixth logo
    // },
];

export default dataPartner;
