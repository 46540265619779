const dataRoadMap =
  // [
  //   {
  //     id: 1,
  //     time: "SEASON 1",
  //     title: "Initial Development & Preparation",
  //     desc: "<ul><li>Smart Contract Development: Finalize and audit the Respect token smart contract to ensure security and functionality.</li><li>Website Launch: Launch the Respect token website with all necessary details for potential supporters and early adopters.</li><li>Community Building: Begin community engagement through online platform.</li><li>Global Marketing Push (Phase 1): Launch the first phase of global marketing efforts, focusing on raising awareness about the Respect token and building interest through TV broadcasting, social media campaigns, influencer partnerships, and PR efforts.</li><li>Initial Partnership Outreach: Begin discussions with various organizations, companies, and sports bodies about potential collaborations, including branding, token utility, and cross-promotion opportunities.</li></ul>",
  //     class: "",
  //   },

  //   {
  //     id: 2,
  //     time: "SEASON 2",
  //     title:
  //       "Pre-Sale Phase 1, Global Marketing Expansion & Community Engagement",
  //     desc: "Pre-Sale Phase 1 Launch: Launch the first phase of the token pre-sale. Offer early bird discounts to attract initial investors.<p> Token Distribution: Distribute tokens to Phase 1 investors. <P/>Claiming Portal: Launch the portal for investors to claim their Respect tokens.",
  //     class: "right",
  //   },

  //   {
  //     id: 3,
  //     time: "Q2 2025",
  //     title: "Pre-Sale Continuation & Ecosystem Development",
  //     desc: "Pre-Sale Phase 2 Launch: Launch the second phase of the token pre-sale with slightly reduced discounts. Token Distribution: Distribute tokens to Phase 2 investors. Staking Feature Development: Begin the development of the staking platform, allowing users to stake Respect tokens. NFT Project Planning: Plan and design the Respect Project NFTs, focusing on unique attributes and rewards.",
  //     class: "",
  //   },

  //   {
  //     id: 4,
  //     time: "Q3 2025",
  //     title: "Final Pre-Sale & Token Utility",
  //     desc: "Pre-Sale Phase 3 Launch: Launch the final phase of the token pre-sale, with tokens available at market price. Token Distribution: Distribute tokens to Phase 3 investors. Launch Claim & Stake Option: Enable users to claim and stake their Respect tokens through the staking platform. NFT Project Launch: Launch the Respect Project NFTs, allowing users to mint and trade their unique NFTs.",
  //     class: "right",
  //   },

  //   {
  //     id: 5,
  //     time: "Q1 2026",
  //     title: "NFT Marketplace & Exchange Development",
  //     desc: "NFT Marketplace Launch: Officially launch the Respect NFT Marketplace, providing a platform for trading Respect Project NFTs. Respect Exchange Planning: Start the development of Respect's own decentralized exchange (DEX) platform. Token Utility Expansion: Explore additional utility options for Respect tokens within the marketplace and exchange ecosystem.",
  //     class: "",
  //   },

  //   {
  //     id: 6,
  //     time: "Q2 2026",
  //     title: "Operational",
  //     desc: "Respect Exchange Launch: Launch the Respect decentralized exchange, allowing users to trade Respect tokens and other cryptocurrencies. Ecosystem Expansion: Continue to develop and expand the Respect ecosystem, integrating new features and partnerships. Ongoing Community Support: Maintain strong community engagement and support to ensure the success of the Respect token and its associated projects.",
  //     class: "right",
  //   },
  // ];

  [
    {
      id: 1,
      time: "SEASON 1",
      title: "Initial Development, Smart Contract Audit & Global Marketing Push",
      desc: "<ul><li> Smart Contract Development: Finalize and audit the Respect token smart contract to ensure robust security and functionality.</li><li>Website Launch: Launch the official Respect token website with comprehensive details for potential supporters and early adopters.</li><li> Community Building: Begin engaging with the community through online platforms and social media.</li><li> Global Marketing Push (Phase 1): Execute the initial phase of global marketing, leveraging TV broadcasting, social media campaigns, influencer partnerships, and public relations to build awareness.</li><li> Partnership Outreach: Initiate discussions with organizations, companies, and sports bodies about collaborations involving branding, token utility, and cross-promotion opportunities.</li></ul>",
      class: "",
    },
    {
      id: 2,
      time: "SEASON 2",
      title: "Pre-Sale Phase 1, Marketing Expansion & Early Engagement",
      desc: "<ul><li> Pre-Sale Phase 1 Launch: Offer exclusive incentives during the first pre-sale phase to attract early adopters.</li><li> Claiming Portal: Enable a portal for users to claim their Respect tokens securely.</li><li> Global Marketing Push (Phase 2): Expand marketing efforts with targeted campaigns, influencer collaborations, and regional outreach in key markets.</li><li> Partnership Formalization: Establish formal partnerships to integrate Respect tokens into events, branding, and reward systems.</li><li> Community Growth: Increase outreach and engagement through partnerships and social media.</li></ul>",
      class: "right",
    },
    {
      id: 3,
      time: "SEASON 3",
      title:
        "Pre-Sale Phase 2, Ecosystem Development & Marketing Continuation",
      desc: "<ul><li> Pre-Sale Phase 2 Launch: Conduct the second pre-sale phase with adjusted benefits.</li><li> Community Events: Host events to increase community interaction and token awareness.</li><li> Collaborations: Strengthen relationships with organizations to integrate Respect tokens into diverse activities.</li><li> Global Marketing Push (Phase 3): Intensify marketing efforts through media, influencers, and events.</li></ul>",
      class: "",
    },
    {
      id: 4,
      time: "SEASON 4",
      title: "Pre-Sale Phase 3 & Utility Expansion",
      desc: "<ul><li>Pre-Sale Phase 3 Launch: Offer tokens at competitive rates in the third pre-sale phase.</li><li> Ecosystem Development: Expand token utility through ecosystem and partner integrations.</li><li> Sector Partnerships: Collaborate with businesses across multiple industries to enhance token applications.</li><li> Global Marketing Push (Phase 4): Focus on new regions, influencer outreach, and platform partnerships.</li></ul>",
      class: "right",
    },
    {
      id: 5,
      time: "SEASON 5–10 Highlights",
      title: "For Seasons 5 through 10, progression with additional pre-sale phases, expanded partnerships, marketplace development, digital asset planning, and the ecosystem's full launch.The marketing push scales globally, culminating in public exchange listing and integration of digital assets.",
      desc: "<ul><li>Pre-Sale Phase 4 to 10: Attract supporters with reduced discounts or near-market prices.</li><li>Marketplace Development: Build a platform for NFT and asset trading.</li><li>Digital Asset Launch: Introduce assets integrated with Respect tokens.</li><li>Ecosystem Expansion: Deploy token utility in broader sectors, events, and sponsorships.</li><li>Public Exchange Listing: Provide public access and liquidity for Respect tokens.</li></ul>",
      class: "",
    },
  
  ];

export default dataRoadMap;
