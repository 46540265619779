import React, { useState, useEffect } from "react";
import USDC from "../../assets/images/logo/usdcc.png";
import Ethereumlogo from "../../assets/img/logo/Ethereumlogo.png";
import meta from "../../assets/images/logo/meta.png"
import walletsd from "../../assets/images/logo/wallet.png"
import { ProgressBar } from 'react-bootstrap';
import trust from "../../assets/images/logo/trust.png"
import coin from "../../assets/images/logo/coin.png"
import { switchChain } from '@wagmi/core'
import { useCallback } from "react";
import { erc20ABI, useAccount, useConnect, useBalance } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { waitForTransaction } from "@wagmi/core";
import { ScrollLink } from 'react-scroll';
import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import img from "../../assets/images/layouts/deco.png";
import Toast from "../Toast";
import imgFighter from "../../assets/images/layouts/header-image.png";
import {
  preSaleReadFunction,
  preSaleWriteFunction,
  usdtReadFunction,
  usdtWriteFunction,
} from "../../ConnectivityAssets/hooks";
import { formatUnits, parseUnits } from "@ethersproject/units";
import {
  preSaleAddress,
  usdtAddress,
} from "../../ConnectivityAssets/environment";
import { writeContract, readContract } from "@wagmi/core";
import { Modal, Button } from 'react-bootstrap';





const initialTimer = 1296000; // 1 hour for example, adjust as needed

const boxStyle = {
  background:
    "linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%)",
  backdropFilter: "blur(4px)",
  padding: "12px",
  position: "relative",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  width: "177px",
  height: "102px",
  borderRadius: "20px",
  overflow: "hidden",
  display: "flex", // Added for vertical centering
  flexDirection: "column", // Added for vertical centering
  alignItems: "center", // Added for horizontal centering
  justifyContent: "center", // Added for vertical centering
  margin: "0 5px", // Added for 10px gap between boxes (5px on each side)
};

const numberStyle = {
  color: "#FFF",
  fontSize: "32px",
  fontWeight: 700,
};

const labelStyle = {
  marginTop: "12px",
  //color: "#697783",
  color: "#CED1D3",
  fontSize: "16px",
  fontWeight: 400,
  textTransform: "uppercase", // Added to capitalize the labels
};

// Example countdown values
const countdownValues = {
  days: 10,
  hours: 5,
  minutes: 30,
  seconds: 15,
};

const headingBoxStyle = {
  background:
    "linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%)",
  backdropFilter: "blur(2px)",
  borderRadius: "20px",
  display: "flex", // For horizontal alignment
  justifyContent: "center", // For horizontal alignment
  alignItems: "center", // For vertical alignment
  // padding: "12px", // Optional, adjust to your liking
  // marginBottom: "20px", // Optional, for spacing between heading and boxes
  width: "100%",
  textAlign:"center"
};
const headingBoxStyle2 = {
  background:
    "linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%)",
  backdropFilter: "blur(2px)",
  borderRadius: "20px",
  display: "flex", // For horizontal alignment
  justifyContent: "center", // For horizontal alignment
  alignItems: "center", // For vertical alignment
  marginBottom: "20px", // Optional, for spacing between heading and boxes
  width: "100%",
  height:'30px',
  color: "#FFF", // Assuming white, adjust as per your theme
  fontSize: "18px", // Adjust as per your preference
  fontWeight: 600, // Adjust as per your preference
  textTransform: "uppercase", 
  boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset"
};
const isMobile = window.innerWidth <= 768;
const headingTextStyle = {
  // Since you're using <h4>, these styles are optional. Adjust as needed...
  color: "#FFF", // Assuming white, adjust as per your theme
  fontSize: "20px",
  fontWeight: 600, // Adjust as per your preference
  textTransform: "uppercase", // If you want the title in uppercase
};

const containerStyle = {
  display: "flex",
  flexDirection: "column", // Stack elements vertically
  alignItems: "center", // Center horizontally
  width: "100%",
};


const countdownStyle = {
  marginBottom: "10px", // Space between countdown and inputs
  marginTop: "10px",
  fontSize: isMobile ? "20px" : "25px", // Adjust font size based on screen width
  fontWeight: "600",
  textAlign: "left",
  color: "#fff",
};

const formContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

const inputBoxStyle = {
  // Your existing inputBoxStyle
  background: "rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(2px)",
  borderRadius: "10px",
  border: "1px solid rgba(255, 255, 255, 0.1)",
  font: "300 14px/1.5 sans-serif",
  color: "#fff",
  padding: "15px",
  boxShadow: "none",
  flex: 1,
  height: "54px",
  marginRight: "5px", // Gap between inputs
  placeholder: { color: "#fff" }, // Set placeholder color to white
};

const inputBoxStyleLast = {
  ...inputBoxStyle, // Inherit all styles from inputBoxStyle
  marginRight: "0px", // Reset margin for the last input
};

function LoginBanner({ onLogin ,password, setPassword}) {
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  
    // Set target date (1st January 2025)
    const [show, setShow] = useState(false); // State to control modal visibility

    const handleClose = () => setShow(false); // Close the modal
    const handleShow = () => setShow(true); // Open the modal

    const [show2, setShow2] = useState(false); // State to control modal visibility

    const handleClose2 = () => setShow2(false); // Close the modal
    const handleShow2 = () => setShow2(true); // Open the modal
    const targetDate = new Date("2025-01-01T00:00:00");

    // Get the current date and time
    const now = new Date();
  
    // Calculate the difference between now and the target date
    const initialTimer = Math.floor((targetDate - now) / 1000); // in seconds
  
    // Initialize countdown values
    const [countdownValues, setCountdownValues] = useState({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
  
    const [timer, setTimer] = useState(initialTimer);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        if (timer > 0) {
          setTimer(timer - 1); // Decrease timer by 1 second
          updateCountdownValues(timer - 1);
        } else {
          clearInterval(intervalId); // Stop the countdown when the timer reaches 0
        }
      }, 1000); // Update every 1 second
  
      return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [timer]);
  
    const updateCountdownValues = (timeLeft) => {
      const days = Math.floor(timeLeft / (60 * 60 * 24)); // Calculate days
      const hours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60)); // Calculate hours
      const minutes = Math.floor((timeLeft % (60 * 60)) / 60); // Calculate minutes
      const seconds = timeLeft % 60; // Calculate seconds
  
      setCountdownValues({ days, hours, minutes, seconds });
    };
  
    useEffect(() => {
      updateCountdownValues(timer); // Initialize countdown values when component mounts
    }, []); 

  const handleOpenUsers = () => {
    // Replace 'example.pdf' with the path to your PDF file
    // Create a new window (popup)
   window.open("https://app.respecttoken.com", "_blank");

    // Set the content of the new window to display the PDF inside an iframe
   // Close the document stream to render itttt
  };

  
  const [currentPrice, setCurrentPrice] = useState(0);
  const [nextPrice, setNextPrice] = useState(0);
  const [sold, setSold] = useState(0);
  const [usdtToRaised, setUsdtToRaised] = useState(0);
  const [usdtTotalRaised, setUsdtTotalRaised] = useState(0);
  const [transectionData, setTransectionData] = useState(null);
  const [amount, setAmount] = useState(0);
  const [presaleId, setPresaleId] = useState(1);
  const [youWillGet, setYouWillGet] = useState(0);
  const [minimumButToken, setMinimumBuyToken] = useState(0);
  const [canbuy, setCanBuy] = useState(null);
  const [Allownce, setAllownce] = useState(0);
  const [activeCurrencry, setActiveCurrency] = useState("ETH");
  const [loading, setLoading] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showIneligibilityModal, setShowIneligibilityModal] = useState(false);
  const [error,setError] = useState(false)
//   const [password, setPassword] = useState("");
  const handleVerifyClose = () => setShowVerifyModal(false);
  const handleVerifyShow = () => setShowVerifyModal(true);

  const handleConfirmClose = () =>{
     setShowConfirmationModal(false);
     setIsChecked(false)
    }
  const handleConfirmShow = () =>{ setShowConfirmationModal(true)
    setShowVerifyModal(false)};

  const handleIneligibilityClose = () => setShowIneligibilityModal(false);
  const handleIneligibilityShow = () => {
    setShowIneligibilityModal(true) 
    setShowVerifyModal(false)
  };
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showToast = (msg, type) => {
    return setAlertState({
      open: true,
      message: msg,
      severity: type,
    });
  };
  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const { data, isError, isLoading } = useBalance({
    address: address,
  });
  
  const connectionHandler = React.useCallback(() => {
    setIsChecked(false)
    setShowConfirmationModal(false)
    if (address) {
      return open();
     
    }
    open();
    
  }, [address, open]);



  const init = useCallback(async () => {
    try {
      // setLoading(true);

      const id = await preSaleReadFunction("presaleId");

      setPresaleId(id);
      const presaleData = await preSaleReadFunction("presale", [id]);

      const cPrice = 1 / Number(formatUnits(presaleData[2], 18));
      setCurrentPrice(cPrice);
      console.log(currentPrice,"hunain")

      const nPrice = 1 / Number(formatUnits(presaleData[3], 18));
      setNextPrice(nPrice);
      console.log(nPrice,"hunainn")

      const soldtoken = Number(formatUnits(presaleData[4], 18));
      setSold(soldtoken);
      let total_raised;
      const usdtRaise = Number(formatUnits(presaleData[7], 6));
      total_raised = usdtRaise + 38390;
      setUsdtToRaised(total_raised);

      const totalRaised = Number(formatUnits(presaleData[6], 6));
      setUsdtTotalRaised(totalRaised);

      // Minimum Buy tokennn
      const minumBuyT = await preSaleReadFunction("MinTokenTobuy");
      setMinimumBuyToken(Number(formatUnits(minumBuyT, 18)));
      setPresaleId(id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      /* empty */
    }
  }, []);

  useEffect(() => {
    init();
  }, [init]);

  const fetchAllowance = useCallback(async () => {
    try {
      const allow = await readContract({
        address: usdtAddress,
        abi: erc20ABI,
        functionName: "allowance",
        args: [address, preSaleAddress],
      });

      setAllownce(formatUnits(allow, 6));
    } catch (error) {
      console.error("Failed to fetch allowance", error);
    }
  }, [address]);

  useEffect(() => {
    const updateCanBuy = () => {
      if (Number(Allownce) >= Number(amount)) {
        setCanBuy(true);
      } else {
        setCanBuy(false);
      }
    };
    updateCanBuy();
  }, [Allownce, amount]);

  // Onchange Function
  const onAmountChange = async (e) => {
    try {
      const inputAmount = e.target.value;
      setAmount(inputAmount);

      if (
        inputAmount === "" ||
        inputAmount === "0" ||
        isNaN(Number(inputAmount))
      ) {
        setYouWillGet(0);
        setLoading(false);
        return;
      }

      if (activeCurrencry === "ETH") {
        const ethToToken = await preSaleReadFunction("ethToTokens", [
          presaleId,
          parseUnits(inputAmount).toString(),
        ]);

        setYouWillGet(Number(formatUnits(ethToToken, 18)));
      } else {
        const usdtToToken = await preSaleReadFunction("usdtToTokens", [
          presaleId,
          parseUnits(inputAmount, 6).toString(),
        ]);

        setYouWillGet(Number(formatUnits(usdtToToken, 18)));
      }
    } catch (error) {
      setLoading(false);
      showToast("Error during Transection !", "error");
      console.log("Error ....", error);
    }
  };

  const approveUSDT = async () => {
    setLoading(true);
    try {
      const { hash } = await writeContract({
        address: usdtAddress,
        abi: erc20ABI,
        functionName: "approve",
        args: [preSaleAddress, parseUnits(amount, 6).toString()],
      });
      const receipt = await waitForTransaction({ hash });
      if (hash && receipt) {
        await fetchAllowance();
        showToast(`${amount} USDT Approve`);
      } else {
        throw new Error("Approval transaction failed");
      }
    } catch (error) {
      console.error("Approval failed", error);
      showToast("Approval failed: " + error.message, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (address) {
      fetchAllowance();
    }
  }, [address, fetchAllowance]);

  // Add Transection Function

  // Function to save data to Google Sheet
  const saveData = async (responce) => {
    console.log("respnce.....", responce);
    const date = new Date().toISOString();
    const data = [
      date,
      address,
      responce.transactionHash,
      amount,
      activeCurrencry,
      youWillGet,
    ];
    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycby8bpalfahYfZDKHrmzSVmbFrV7ZhDbEBaQa2Y1uTVEJINxqpyimaGLc0Pw0_LfgdgA7A/exec",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      const result = await response.text();
      console.log(result);
      setAmount(0);
      setYouWillGet(0);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  // Buy Tokens

  const buyHandler = async (e) => {
    e.preventDefault();
    // validation
    if (isNaN(Number(amount))) {
      showToast("Invalid Input !", "error");
      return;
    }
    if (Number(youWillGet) < minimumButToken) {
      showToast(
        `The minimum ammount to buy  is ${minimumButToken}RESPECT.`,
        "error"
      );
      return;
    }
    try {
      setLoading(true);
      // buy with ETH

      if (activeCurrencry === "ETH") {
        // Call the function to buy with ETH
        
          const res = await preSaleWriteFunction(
            "buyWithEth",
            [],
            parseUnits(amount, 18).toString()
          );
          saveData(res);
          setAmount(0);
          setYouWillGet(0);
          setLoading(false);
          handleShow()
        

        // setTransectionhash(res);
        // setOpenD(true);
      } else {
        // buy with USDT

        const res = await preSaleWriteFunction("buyWithUSDT", [
          parseUnits(amount, 6).toString(),
        ]);
        saveData(res);
        setAmount(0);
        setYouWillGet(0);
        handleShow()
        // setTransectionhash(res);
        // setOpenD(true);
      }

      init();
      setLoading(false);
    } catch (error) {
      if (error.message.includes("account does not have enough funds")) {
        // setError(true);  // Set the error state
        handleShow2() // Show the toast with error message
      } else {
        // showToast(error.message, "error");
      }
  
      console.log("Error ...", error);
    }

    /// buy start

  };

  
 
  const progress = (usdtToRaised / usdtTotalRaised) * 100;

  return (
    <>
       {/* <Button variant="primary" onClick={handleShow2}>
        Show Transaction Success
      </Button> */}
{/* // modal box  */}
  {/* Modal Box */}
        <style>
        {`
          .modal-header .btn-close {
            color: white !important; /* Make the cross icon white */
            background: none; /* Remove the default background */
          }
        `}
      </style>

    <section className="banner s3">
      <div className="shape"></div>
      <div className="shape right"></div>
      <div className="shape s3 right"></div>

      <div className="container big">
        <div className="row">
          <div className="col-xl-5 col-md-12">
            <div className="banner__left">
              <div className="block-text">
                <h4 className="heading">
                  Empowering the Taekwondo and Sports Community{" "}
                  <span className="arlo_tm_animation_text_word"></span>
                </h4>
                <p className="desc">
                Introducing Respect Token, a groundbreaking initiative designed to empower and reward the Taekwondo 
                and sports community. Built to honor the values of discipline, respect, and excellence, Respect Token 
                represents unity and growth, bringing together practitioners, fans, and enthusiasts in a shared vision of 
                progress and connection.
                </p>
                <h5 className="heading">
                  {" "}
                  <span className="arlo_tm_animation_text_word">
                  As Featured on ESPN Sports Network{" "}
                  </span>
                </h5>
                <p className="desc">
                Respect Token is making headlines across the globe, proudly featured on the renowned ESPN Sports 
                Network. This recognition highlights our mission to uplift and connect the worldwide Taekwondo 
                community, celebrating the values of discipline, respect, and excellence that define martial arts.
                <br />
                <br />
                Through its feature on ESPN, Respect Token is showcased as a pioneering initiative that unites 
                practitioners, fans, and enthusiasts on a global scale. It’s more than a platform—it’s a movement that 
                honors the vibrancy and strength of the martial arts ecosystem while fostering a sense of unity and 
                progress.
                </p>

                <Link    onClick={handleConfirmShow} className="action-btn">
                  {/* <span>Buy RESPECT</span> */}
                </Link>
              </div>

             
            </div>
          </div>
          <div className="col-xl-7 col-md-12">
            <div className="banner__right" style={{ padding: "0" }}>
              <div className="image-1">
                <img src={imgFighter} width={800} alt="Cyfonii" />
              </div>

              <div
                className="banner-box"
                data-aos="fade-up"
                data-aos-duration="2000"
                style={{
                  width: "83%",
                  position: "absolute",
                  top: "330px",
                  backdropFilter: "none",
                }}
              >
                {/* <div
                  className="banner-box"
                  style={{ alignItems: "center", justifyContent: "center" }}
                >
                  <h4 className="title">Next Price Increase</h4>
                </div> */}
                <div style={headingTextStyle}>
                  <h5 style={{paddingTop:"10px",paddingBottom:"10px",textAlign:"center"}}>
                    Login to visit Admin Dashboard
                    </h5>
                    </div>
                <div className="top">
                  {/* <h4 className="title">Ninja 3D Digital Artwork</h4> */}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      alignSelf:"center",
                      alignContent:"center",
                      borderBottom:
                        "border-bottom: 1px solid rgba(255, 255, 255, 0.1);",
                      
                    }}
                  >
                    {/* <div  id="mrd" style={headingBoxStyle}>
                      <h4 style={headingTextStyle} className="title">
                        Login to visit Admin Dashboard
                      </h4>
                      
                    </div>
                     */}
                    
                    {/* <div style={headingBoxStyle2}>
                      
                      <ProgressBar
        now={progress} // Set the progress value
        // label={`${Math.round(progress)}%`} // Show percentage label insideeeEE
        variant="blue"
        className="w-100 h-100"
        style={{ height: '100px',margin:'0',borderRadius:'20px',boxShadow: "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset" }} // You can customize the color if you like
      />
     
     <h4 style={{position:'fixed',fontSize:'15px',color:'black'}} className="title">
     {((usdtToRaised / usdtTotalRaised) * 100).toFixed(2)} %
                      </h4>   
                      
                    </div> */}
                    <div className="done">
                      {/* Container for the boxes */}
                      <div className="countdown-box bnm" style={{height:'0px',background:'none',opacity:'0'}}>
                        {/* <span className="mn" style={numberStyle}>00</span>
                        <span className="mn" style={labelStyle}>DAY(S)</span> */}
                      </div>
                      <div className="countdown-box bnm" style={{height:'0px',background:'none',opacity:'0'}}>
                        {/* <span className="mn" style={numberStyle}>00</span>
                        <span className="mn" style={labelStyle}>HOUR(S)</span> */}
                      </div>
                      <div className="countdown-box bnm" style={{height:'0px',background:'none',opacity:'0'}}>
                        {/* <span className="mn" style={numberStyle}>
                          00
                        </span>
                        <span className="mn" style={labelStyle}>MINUTE(S)</span> */}
                      </div>
                      <div className="countdown-box bnm" style={{height:'0px',opacity:'0'}}>
                        {/* <span className="mn" style={numberStyle}>
                          00
                        </span>
                        <span className="mn" style={labelStyle}>SECOND(S)</span> */}
                      </div>
                    </div>
                  </div>

                  {/* <div className="wishlist">
                    <div className="icon">
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.25 3.8125C11.25 2.36292 10.0256 1.1875 8.51533 1.1875C7.38658 1.1875 6.41708 1.84433 6 2.78175C5.58292 1.84433 4.61342 1.1875 3.48408 1.1875C1.975 1.1875 0.75 2.36292 0.75 3.8125C0.75 8.02417 6 10.8125 6 10.8125C6 10.8125 11.25 8.02417 11.25 3.8125Z"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <p>3432</p>
                  </div> */}
                </div>
                <div className="main">
                  <div className="info" style={{ width: "37%" }}>
                    <p style={{ color: "#CED1D3",fontWeight:"600" , fontSize:"20px"}}>Current</p>
                    <h4>1 RESPECT</h4>
                    <h4 style={{ color: "#CED1D3" }}>${currentPrice}</h4>
                  </div>

                  <div style={containerStyle}>
                    <div style={countdownStyle} className="coundown">
                      <p style={{ color: "#fff" }}>
                        AMOUNT RAISED: ${usdtToRaised.toFixed(2)} 
                        {/* / $
                        {usdtTotalRaised.toFixed(2)} */}
                      </p>
                      <span
                        className="js-countdown"
                        data-timer="12865550"
                      ></span>
                    </div>

                    <div style={countdownStyle}>
                      {activeCurrencry === "ETH"?
                      <div className="flex flex-col">
                      <div className='text-sm text-light-white'>Enter Admin Password</div>
                      <div style={inputBoxStyle}>
                        
                      {/* <input
                        type="text"
                        className="input-placeholder"
                        placeholder="Amount in you pay"
                        value={amount}
                        required
                        onChange={onAmountChange}
                        
                      /> */}
                       <input
        type="password"
        placeholder="Enter Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{
            width: "100%",
            background: "transparent",
            border: "none",
            outline: "none",
            color: "#fff",
          }}
      />
                    </div></div>:<div className="flex flex-col">
                   
                     </div>}
                    
                    </div>
                    
                    <div
               
                    
                    // style={{
                    //   border: "1px solid #B5B9BE",
                    //   color: "#000",
                    //   fontWeight: "600",
                    //   margin: 2,
                    //   width: "40%",
                      
                    // }}
                  >
                    <button type="submit" className="action-btn">
                    
                    <span>
                      LOGIN{" "}
                      <svg
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.5 16.5L19 9M19 9L11.5 1.5M19 9H1"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  
                  </button>
                  </div>
                  
               

                 
                  </div>
                  
                </div>
                
                
                
               
              </div>
              
            </div>
           
          </div>
         
        </div>
        
      </div>
      
      
    </section>
    
    </>
  );
}

export default LoginBanner;
